import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";

import "./home.css";
import "./../../App.css";
import Kitchener from "../../images/Kitchener.jpg";

import Comfort from '../../images/comfort.jpg'
import DesignQuality from '../../images/designquality.jpg'
import Energy from '../../images/energy.jpg'



const Home = () => {
 
  return (
    <div>
      <div className="section">
        <div className="overlay-text">
          <div className="banner_text">
            <div className="container">
              <div className="inner_text">
                <h1>
                  Building houses with Integrity, Trust, Quality workmanship &
                  Exceptional Standards
                </h1>
                <p>
                  Forward-thinking design plays a vital role in every home we
                  build, from everything you see to everything you don’t.
                </p>
              
                <div >
                    <a href="/contact-us">
                    <button className="download-button">REQUEST OUR BROCHURE</button>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </div>

      <section className="custom-section sec-padd">
        <div className="container">
          <div className="row g-4 align-items-center justify-content-between">
            <div className="col-md-5">
              <h2 className="houzez_section_title mb-4">
                Find a home that suits your lifestyle.
              </h2>
              <a className="link_a" href="contact-us/index.html">
                Talk to an expert
              </a>
            </div>
            <div className="col-md-6">
              <div className="row g-4 ">
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M11.379 5.871L2.893 14.357 0.772 12.235"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M2.893 10.114L5.015 12.235"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M13.5,6.75a3,3,0,1,1,2.123-5.121,3.029,3.029,0,0,1,.478.621"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M11.25,18v3.75a1.5,1.5,0,0,0,1.5,1.5h6a1.5,1.5,0,0,0,1.5-1.5V18"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M23.25,17.25l-6.5-5.847a1.5,1.5,0,0,0-2.006,0L8.25,17.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M21.75 15.9L21.75 12"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M13.5,3.75h0A2.25,2.25,0,0,1,15.75,6v9"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      {" "}
                      <span>Turnkey delivery</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M10.248,21.1a7.532,7.532,0,0,1-7.531-7.532C2.717,2.645,15.861,8.252,22.189,3a.466.466,0,0,1,.479-.048.5.5,0,0,1,.3.378C24.9,14.643,16.572,21.1,10.248,21.1Z"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M13.261,11.554A24.984,24.984,0,0,0,4.652,15.84L.75,19.17"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      {" "}
                      <span>Eco-friendly</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M19.025,15.375a9,9,0,0,0-14.05,0"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M3.000 9.750 A9.000 9.000 0 1 0 21.000 9.750 A9.000 9.000 0 1 0 3.000 9.750 Z"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M12 18.75L12 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M7.5 23.25L16.5 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M7.5,13.205V7a3.006,3.006,0,0,0-.6-1.8L5.582,3.443"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M16.5,13.205V7a3.006,3.006,0,0,1,.6-1.8l1.319-1.757"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      <span>High-end materials</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M19.025,15.375a9,9,0,0,0-14.05,0"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M3.000 9.750 A9.000 9.000 0 1 0 21.000 9.750 A9.000 9.000 0 1 0 3.000 9.750 Z"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M12 18.75L12 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M7.5 23.25L16.5 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M7.5,13.205V7a3.006,3.006,0,0,0-.6-1.8L5.582,3.443"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M16.5,13.205V7a3.006,3.006,0,0,1,.6-1.8l1.319-1.757"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      <span>Top know-how</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M.75,23.25H5.571a1.966,1.966,0,0,0,1.929-2v-2a1.966,1.966,0,0,0-1.929-2H.75"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M5.641,23.25H21.75a1.5,1.5,0,0,0,1.5-1.5v-3a1.5,1.5,0,0,0-1.5-1.5H5.641"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M20.25 17.25L20.25 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M17.25 17.25L17.25 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M14.25 17.25L14.25 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M11.25 17.25L11.25 23.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M4.5 20.25L0.75 20.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M16.2,17.25V16a.549.549,0,0,0-.388-.513A3.743,3.743,0,0,1,13.2,11.75c0-3.789,4.651.945,4.8-10.435A.586.586,0,0,0,17.4.75H6.6a.586.586,0,0,0-.6.565c.148,11.38,4.8,6.646,4.8,10.435a3.743,3.743,0,0,1-2.614,3.741A.548.548,0,0,0,7.8,16V17.25Z"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M6.023,2.25H3.55A.536.536,0,0,0,3,2.826c.131,1.379.774,4.752,3.925,4.673"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M17.977,2.25h2.542a.535.535,0,0,1,.547.576c-.132,1.389-.783,4.8-3.992,4.67"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      {" "}
                      <span>Awarded company</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="svg-item">
                    <span className="elementor-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width="64"
                        height="64"
                      >
                        <g transform="matrix(2.6666666666666665,0,0,2.6666666666666665,0,0)">
                          <path
                            d="M14.96,17.22l-.633,5.241a.666.666,0,0,0,1.127.591l1.823-1.822L19.1,23.052a.667.667,0,0,0,1.128-.591l-.641-5.277"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M13.500 14.250 A3.750 3.750 0 1 0 21.000 14.250 A3.750 3.750 0 1 0 13.500 14.250 Z"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                          <path
                            d="M10.5,20.25h-6A1.5,1.5,0,0,1,3,18.75V2.25A1.5,1.5,0,0,1,4.5.75H15.129a1.5,1.5,0,0,1,1.06.439l2.872,2.872a1.5,1.5,0,0,1,.439,1.06V8.25"
                            fill="none"
                            stroke="#16bfbf"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <h3 className="svg-title">
                      <span>Certifications</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd-b1 hidden">
        <div className="container-fluid  p-0">
          <div className="row g-4">
            <div className="col-md-7">
              <img src={Kitchener} className="image" />
            </div>
            <div className="col-md-5">
              <div className="overlay_green">
                <div className="text-content">
                  <h1>Healthy homes designed to promote your well-being.</h1>
                  <p>
                    Where you live is everything, which is why we are committed
                    to creating new home environments that elevate your quality
                    of life.
                  </p>
                  <div className="home_green_btn">
                    <a href="/contact-us">
                    <button className="btn_outline">REQUEST OUR BROCHURE</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      <section className="margin_blue">
        <div className=" section-design position-relative">
          <div
            className="elementor-shape elementor-shape-bottom"
            data-negative="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
              ></path>
            </svg>{" "}
          </div>
          <div className="container">
            <div className="line"></div>
            <div className="col-md-8">
              <h2 className="quality">
                Exceptional Quality,{" "}
                <span className="highlight">Exceeding expectations</span>,
                Delivering value by Design
              </h2>
              <p className="subtext">
                For those who seek an exceptional home and life, there is
                Crescent Homes.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd bg_light  box_sec">
        <div className="container">
          <div className="building">
            <h1>
              Building a beautiful
              <span> tomorrow </span>
              for our customers
            </h1>
            <p>
              We founded Crescent Homes to create the opportunity to escape to
              nature in <br /> the comfort of our beautiful buildings.
            </p>
          </div>
          <div className="row g-md-5 g-4">
            <div className="col-md-4 col-sm-6 box">
              <img
                alt="Modern kitchen with white cabinets and a large island"
                src={DesignQuality}
              />
              <h3>Design quality</h3>
              <p>
                Crescent Homes sets out how to get the right quantity of new
                housing, at the right quality, in the right places.
              </p>
              <a href="#">
                <i className="fas fa-plus-circle"></i>
                FIND MORE
              </a>
            </div>
            <div className="col-md-4 col-sm-6 box">
              <img
                alt="Person using a smart home device"
                src={Energy}
              />
              <h3>Energy efficiency</h3>
              <p>
                Relevant to the supply and installation of energy efficient
                measures and associated works.
              </p>
              <a href="#">
                <i className="fas fa-plus-circle"></i>
                FIND MORE
              </a>
            </div>
            <div className="col-md-4 col-sm-6 box">
              <img
                alt="Comfortable living room with modern furniture"
                src={Comfort}
              />{" "}
              <h3>High comfort</h3>
              <p>
                These homes are spacious, modern and comfortable with style and
                affordability in mind to suit any budget.
              </p>
              <a href="#">
                <i className="fas fa-plus-circle"></i>
                FIND MORE
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="home_fixed">
        <div className="high-end">
          <div className="container">
            <div className="ms-auto col-md-5">
              <div className="high-end-content">
                <h1>Only high-end quality materials</h1>
                <p>
                  We believe quality builds require quality materials, and thus
                  we only partner with suppliers in the industry who uphold our
                  expected premium standard for raw materials.
                </p>
              
                <button   >REQUEST OUR BROCHURE</button>
               
               
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <footer>
        <div className="expert-container">
          <div
            className="elementor-shape elementor-shape-bottom elementor-shape-top"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>{" "}
          </div>
          <div className="elementor-background-overlay"></div>
          <div className="container-fluid">
            <div className="row g-md-5 g-4">
              <div className="col-md-9">
                <div className="expert-content text-center">
                  <h1>
                    Would you like to <span>speak to an expert?</span>
                  </h1>
                  <p>
                    Have questions about buying homes? Our experts will answer
                    your questions, provide advice and offer insights.
                  </p>
                  <a className="button" href="#">
                    REQUEST A CONSULTATION
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="logo">
                  <img
                    alt="Tarion Warranty Corporation Registered Builder logo"
                    height="150"
                    src="https://www.crescenthomes.ca/wp-content/uploads/2021/05/WhatsApp-Image-2021-05-20-at-10.12.31-PM.jpeg"
                    width="150"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer">© Crescent Homes - All rights reserved</div>
          </div>
        </div>
      </footer> */}
    </div>
  );
};

export default Home;
