import React from "react";
import { Link } from "react-router-dom";
import GalleryImage from "../../images/gallery/gallery.jpg";
import GalleryImage1 from "../../images/gallery/gallery1.jpg";
import GalleryImage2 from "../../images/gallery/gallery2.jpg";
import GalleryImage3 from "../../images/gallery/gallery3.png";
import GalleryImage4 from "../../images/gallery/gallery4.png";
import GalleryImage5 from "../../images/gallery/gallery5.jpg";
import GalleryImage6 from "../../images/gallery/gallery6.jpg";
import GalleryImage7 from "../../images/gallery/gallery7.jpg";

import Inter1 from '../../images/gallery/inter1.jpg'
import Inter2 from '../../images/gallery/inter2.jpg'
import Inter3 from '../../images/gallery/inter3.jpg'
import Inter4 from '../../images/gallery/inter4.jpg'
import Inter5 from '../../images/gallery/inter5.jpg'
import Inter6 from '../../images/gallery/inter6.jpg'
import Inter7 from '../../images/gallery/inter7.jpg'
import Inter8 from '../../images/gallery/inter8.jpg'
import Inter9 from '../../images/gallery/inter9.jpg'
import Inter10 from '../../images/gallery/inter10.jpg'
import Inter11 from '../../images/gallery/inter11.jpg'
import Inter12 from '../../images/gallery/inter12.jpg'
import Inter13 from '../../images/gallery/inter13.jpg'
import Inter14 from '../../images/gallery/inter14.jpg'
import Inter15 from '../../images/gallery/inter15.jpg'

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const Gallery = () => {
  const ExteriorArray = [
    {
      id: 1,
      image: GalleryImage,
    },
    {
      id: 2,
      image: GalleryImage1,
    },
    {
      id: 3,
      image: GalleryImage2,
    },
    {
      id: 4,
      image: GalleryImage3,
    },
    {
      id: 5,
      image: GalleryImage4,
    },
    {
      id: 6,
      image: GalleryImage5,
    },
    {
      id: 7,
      image: GalleryImage6,
    },
    {
      id: 8,
      image: GalleryImage7,
    },
  ];
  const InteriorArray = [
    {
      id: 1,
      image: Inter1,
    },
    {
      id: 2,
      image: Inter2,
    },
    {
      id: 3,
      image: Inter3,
    },
    {
      id: 4,
      image: Inter4,
    },
    {
      id: 5,
      image: Inter5,
    },
    {
      id: 6,
      image: Inter6,
    },
    {
      id: 7,
      image: Inter7,
    },
    {
      id: 8,
      image: Inter8,
    },
    {
      id: 9,
      image: Inter9,
    },
    {
      id: 10,
      image: Inter10,
    },
    {
      id: 11,
      image: Inter11,
    },
    {
      id: 12,
      image: Inter12,
    },
    {
      id: 13,
      image: Inter13,
    },
    {
      id: 14,
      image: Inter14,
    },
    {
      id: 15,
      image: Inter15,
    },

  ];

  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  return (
    <>
      <section className="banner_sec gallery_banner">
        <div className="container">
          <div className="inner_banner_text">
            <h1>Our Gallery</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/">
                  <span>Home </span>
                </a>
              </li>
              <li>&gt;</li>
              <li>Our Gallery</li>
            </ol>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </section>

      <section className="sec-padd bg_light pt-4 gallery_sec content_sec">
        <div className="container-fluid mb-5">
        <div className="row g-4 align-items-center justify-content-between">
            <div className="col-md-4 text-center">
              <h1 className="heading_1">Exterior view of projects</h1>
              <p>
                At Crescent Homes, we connect the outdoors with the indoors,
                creating inspired modern home designs that are perfectly suited
                to your land. Contact us today!
              </p>
            </div>
            <div className="col-md-7 gallery-4">
              <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
              >
                {ExteriorArray.map((item, index) => {
                  return (
                    <a href={item.image} key={index}>
                      <img
                        alt="exterior"
                        src={item.image}
                        className="gallery-item"
                      />
                    </a>
                  );
                })}
              </LightGallery>

           
            </div>
          </div>
    
     
        </div>
      </section>
      <section className="sec-padd bg_light pt-4 gallery_sec content_sec">
       <div className="container mb-5">
              <div className="row g-4 align-items-center justify-content-between">
              <div className="col-md-7 gallery-5">

<LightGallery
    onInit={onInit}
    speed={500}
    plugins={[lgThumbnail, lgZoom]}

  >
    {InteriorArray.map((item, index) => {
      return (
        <a href={item.image} key={index} >
          <img
            alt="exterior"
            src={item.image}
            className="galleryInt-item"
          />
        </a>
      );
    })}
  </LightGallery>


</div>
            <div className="col-md-4 text-center">
              <h1 className="heading_1">Interior view of projects</h1>
              <p>
                Carefully finished under the supervision of an interior
                decorator, Crescent Homes evoke contemporary chic, whilst
                retaining an inviting and peaceful ambience. Recreating that wow
                factor can be a little tricky, so we do it very carefully to
                bestow that pizazz into your new home.
              </p>
            </div>
        
          </div>
          </div>
      </section>
    </>
  );
};

export default Gallery;
