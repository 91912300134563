import React from "react";

const About = () => {
  return (
    <>
      <section className="banner_sec about_banner">
        <div className="container">
          <div className="inner_banner_text">
            <h1>About us</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/">
                  <span>Home </span>
                </a>
              </li>
              <li>&gt;</li>
              <li>About us</li>
            </ol>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </section>

      <section className="sec-padd bg_light pt-5 content_sec">
        <div className="container">
          <h1 className="heading_c text-center">
            Highest Level of Quality with unique designs
          </h1>
          <div className="row g-md-5 g-4">
            <div className="col-md-6">
              <p>
                Crescent Homes journey started more than a decade ago. It is
                started by entrepreneur Nitin Jain and team of qualified
                professionals with a combined industry experience of more than
                100 years. Superior design, quality craftsmanship, treating
                every home being built as our own family homes are the hallmarks
                of Crescent Homes. Crescent Homes communities are more than a
                home, it’s a lifestyle.
              </p>
              <h3>Mission & Vision</h3>
              <h5>Our mission:</h5>
              <ul>
                <li>
                  To provide high quality and reliable homes to our customers
                </li>
                <li>
                  To provide a home where customers feel proud and be able to
                  enjoy in many years to come
                </li>
                <li>
                  To keep up the largest amounts of demonstrable skill,
                  trustworthiness, genuineness and reasonableness in our
                  associations with our suppliers, subcontractors, expert
                  partners and clients.
                </li>
                <li>To be ethical, sincere and open in everything we do</li>
              </ul>
            </div>

            <div className="col-md-6">
              <h5>Our vision and values:</h5>
              <ul>
                <li>To surpass customers expectations consistently</li>
                <li>To be the best builder in KW area</li>
                <li>To be constantly respected by our customers</li>
              </ul>
              <p>
                Crescent Homes always believe in quality life. We provide great
                locations, innovative products, excellent customer service and
                best price in market. Every home we make is made by a qualified
                team, who look into each possible detail to give our customers
                the best product. We provide energy efficient, healthier &
                comfortable homes. Every member of the team works closely with
                each buyer, to ensure the home is built to their tastes and best
                suitable to meet their family needs. We have an unwavering
                commitment to building. Not just building new homes, but
                building trust, relationships and peace of mind. We are both
                knowledgeable and passionate about every aspect of what we do,
                and we do all we can to communicate that knowledge and passion
                to home buyers. We know how important your new home is to you;
                that’s why each home we build is so important to us. To include
                esteem for customers through advancement, prescience,
                uprightness, and great execution; and to present with character
                and reason that conveys honour, is what Crescent Home strives to
                achieve. Our main goal is to give our employees a fair and
                supportive workplace, where each worker separately and all
                things considered, can devote themselves to furnishing our
                clients with remarkable workmanship, phenomenal administration,
                and expert trustworthiness.
              </p>
            </div>
          </div>

          <div className="row g-md-5 g-4 mt-5 pb-5">
            <div className="col-md-12">
              <h2 className="text-center">Our values</h2>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="value_div">
                <h4>Quality</h4>
                <p>
                  Crescent Homes provides highest quality homes available at
                  competitive prices.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="value_div">
                <h4>Teamwork</h4>
                <p>
                  We are one of the largest and most recognized home builders in
                  the KW area and has been building quality homes with our
                  exceptional team.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="value_div">
                <h4>Innovative</h4>
                <p>
                  Crescent Homes breaks new ground in home building through
                  innovative partnerships & cutting-edge technology
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="value_div">
                <h4>Trust</h4>
                <p>
                  It's all part of Crescent Homes's everyday commitment to
                  providing quality homes and outstanding customer service.
                  Where Trust Is Built.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="value_div">
                <h4>Quality</h4>
                <p>
                  Crescent Homes provides highest quality homes available at
                  competitive prices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
