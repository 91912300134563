import * as React from "react";
import "../../screens/Home/home.css"
import { useNavigate } from "react-router-dom";
import Tarion from '../../images/Tarion.jpg'
import Tarion1 from '../../images/Tarion_1.png'
function Footer() {
  const naviagte = useNavigate();
  return (
    <div>
      <footer>
        <div className="expert-container">
          <div
            className="elementor-shape elementor-shape-bottom elementor-shape-top"
            data-negative="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="elementor-shape-fill"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
	c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
	c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>{" "}
          </div>
          <div className="elementor-background-overlay"></div>
          <div className="container-fluid">
            <div className="row g-md-5 g-4">
              <div className="col-md-9">
                <div className="expert-content text-center">
                  <h1>
                    Would you like to <span>speak to an expert?</span>
                  </h1>
                  <p>
                    Have questions about buying homes? Our experts will answer
                    your questions, provide advice and offer insights.
                  </p>
                  <a className="button"  onClick={(()=>{
                    naviagte('/contact-us')
                  })}>
                    REQUEST A CONSULTATION
                  </a>
                </div>
              </div>
              <div className="col-md-3">
                <div className="logo">
                  <img
                    alt="Tarion Warranty Corporation Registered Builder logo"
                    height="150"
                    //src={Tarion}
                    src={Tarion1}
                    width="150"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer">© Crescent Homes - All rights reserved</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
