

import React from "react";

const Condos = () => {
  return (
    <>
    <section className="banner_sec service_banner">
      <div className="container">
        <div className="inner_banner_text">
          <h1>Condos </h1>
          <ol className="breadcrumb">
            <li>
              <a href="/">
                <span>Home </span>
              </a>
            </li>
            <li>&gt;</li>
            <li>Condos</li>
          </ol>
        </div>
      </div>
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
          ></path>
        </svg>{" "}
      </div>
    </section>

    <section className="sec-padd pt-5 service_content">
      <div className="container">
        <h2>EXTERIOR AND CONSTRUCTION FEATURES</h2>
        <ul>
          <li>Lot graded to the requirements as per respective city</li>
          <li>
            Top soiled and sodded at front, side, back and boulevards or to
            the tree saving line established by the city (except where erosion
            control measures are in place ie hydro seeding) and the slope is
            to remain in its current state and not maintained or otherwise
            disturbed
          </li>
          <li>
            Concrete porch, precast steps as per plan to the front door, patio
            stone walkway to driveway.
          </li>
          <li>
            Aluminum railings on front porch only when required by code.
            Railing color to coordinate with soffit package selected *Exterior
            painted pillar(s) and Ornamental exterior trim to be coordinated
            to exterior color selection
          </li>
          <li>
            Brick front main floor with vinyl siding on remainder as per plan
            (color to be chosen from Builder’s samples)
          </li>
          <li>Address Stone above garage 10″ x 12″</li>
          <li>25 year Asphalt shingles</li>
          <li>
            Maintenance free aluminum soffits, fascia, and downspouts
            (Downspouts will vary from model home and may change per grading
            requirements)
          </li>
          <li>Complete exterior caulking as required</li>
          <li>Two outside hose bibs</li>
          <li>
            Poured concrete foundation with superior damp proof membrane
          </li>
          <li>
            Basecoat asphalt paved driveway (Driveway paved within 3 seasons
            of sidewalks being installed)
          </li>
          <li>Driveway approach completed to city specifications</li>
          <li>Weather stripping on all exterior doors and windows</li>
          <li>Vinyl clad white windows with screens Energy Star qualified</li>
          <li>Deadbolts and locks on all doors to code</li>
        </ul>
      </div>
    </section>
    <section className="sec-padd pt-5 bg_light service_content">
      <div className="container">
        <h2 className="heading_mix">
          Discover our<span> solutions </span>for your new home
        </h2>
        <h5>GARAGE</h5>
        <ul>
          <li>Poured concrete floor</li>
          <li>Dry walled, taped and fume proofed (entire interior)</li>
          <li>Spray insulated in ceiling space beneath second storey</li>
          <li>
            Sectional non-insulated steel roll-up garage door as per plan
            (with lock).
          </li>
          <li>
            Color of garage door to be coordinated with exterior selections
          </li>
          <li>Rough in for automatic garage door opener</li>
        </ul>
        <h5>INSULATION</h5>
        <ul>
          <li>
            Ceiling insulation to be minimum Energy Star qualified including
            cathedral and exposed floors
          </li>
          <li>Exterior walls to be insulated to code</li>
          <li>Exterior basement walls to be insulated to code</li>
        </ul>
        <h5>ELECTRICAL/MECHANICAL</h5>
        <ul>
          <li>
            Exterior and bathroom receptacles to have ground fault interrupt
          </li>
          <li>
            Heavy-duty electrical cable outlet for dryer and electric stove
          </li>
          <li>Furnace duct work to accommodate future air conditioner</li>
          <li>Christmas light receptacle to front porch, includes switch</li>
          <li>
            100 AMP service with circuit breaker panel and copper wire
            throughout to Ontario Hydro specifications
          </li>
          <li>
            Light fixture in each room. Receptacles and switches as required
            by the Ontario Building Code
          </li>
          <li>Interior door chime</li>
          <li>Interior smoke and CO2 detectors as required by code</li>
          <li>
            3 cable and 3 telephone receptacles, connection in basement
            (Townhomes have 2 each)
          </li>
          <li>
            2 exterior weather proof receptacles (one in front and one in rear
            of house)
          </li>
          <li>
            2 exterior frost proof water taps (one in garage and one at the
            rear of the house)
          </li>
          <li>Rough-in and receptacle for future garage opener</li>
          <li>Heavy duty stove and dryer connections</li>
          <li>Single white plastic laundry tub</li>
          <li>High efficiency Energy Star™ rated forced air gas furnace</li>
          <li>
            Natural Gas rental, hot water heater provided by Kitchener
            Utilities
          </li>
          <li>2 piece rough-in basement (Townhomes)</li>
        </ul>
        <h5>BASEMENT</h5>
        <ul>
          <li>High efficiency forced air gas furnace</li>
          <li>High efficiency gas hot water heater (rented)</li>
          <li>Floor drain</li>
          <li>Insulation, vapor barrier to code</li>
          <li>Steel beam (where applicable)</li>
          <li>HRV installed in Single Family Homes</li>
          <li>Steel studs to floor</li>
        </ul>

        <h5>WASHROOMS</h5>
        <ul>
          <li>
            Full four piece main and ensuite bath (shower, tub, toilet and
            sink) on bedroom level of all models (or as per plan)
          </li>
          <li>
            Pedestal sink and toilet on main floor, all fixtures white (as per
            plan)
          </li>
          <li>
            Ceramic tile up to ceiling in all bath tub surrounds (as per plan)
          </li>
          <li>Quality ceramic tile flooring (as per Builder’s samples)</li>
          <li>
            Vanity to main bath with 180 degree formed counter top (from
            builder’s samples)
          </li>
          <li>
            Towel bar, paper roll dispenser, toilet seat, mirror and shower
            curtain rod installed
          </li>
          <li>
            Exhaust fans installed in washrooms are on a separate switch and
            exhausted to outside
          </li>
          <li>Pressure control valve in main shower</li>
          <li>Water supply and drain lines</li>
          <li>Single lever taps in sinks and shower</li>
        </ul>

        <h5>KITCHEN</h5>
        <ul>
          <li>
            Double stainless steel kitchen sink with chrome single lever
            faucet
          </li>
          <li>2 speed exhaust fan over stove vented to the exterior</li>
          <li>Heavy duty receptacle for electric stove</li>
          <li>
            Dedicated electrical outlet for refrigerator. Fridge opening
            standard 30″ wide x 66″ high, opening for stove is 30″ wide,
            Dishwasher opening is 24″ wide x 34 1/2″ high
          </li>
          <li>Electrical outlets at counter level for small appliances</li>
          <li>Water supply and drain lines</li>
          <li>
            Rough in dishwasher (includes connector to hot water and drain
            under kitchen sink – rough-in wiring to the electrical panel but
            not connected)
          </li>
        </ul>

        <h5>FLOORING</h5>
        <ul>
          <li>
            Quality ceramic tile flooring for all hard surface areas as
            indicated on floor plan (from Builder’s samples)
          </li>
          <li>
            Quality 32oz broadloom with high density 4lb underpad (from
            Builder’s samples)
          </li>
          <li>5/8″ tongue and groove sub floor, screwed down</li>
        </ul>
        <h5>DOORS</h5>
        <ul>
          <li>White sliding closet doors</li>
          <li>Deadbolts on all exterior doors</li>
          <li>
            White Two-Panel Smooth Finish doors with colour matching sliding
            closet doors, complete with 2 3/8″ trim and casings, and 4 1/8″
            baseboard
          </li>
        </ul>
        <h5>PAINTING</h5>
        <ul>
          <li>
            Exterior front man door, sidelight, columns and ornamental
            exterior trim, to be colour coordinated with exterior color
            selection
          </li>
          <li>
            All interior walls decorated with latex paint, one color
            throughout over primer coat
          </li>
          <li>All interior trim and casings to be painted white</li>
        </ul>

        <h5>LAUNDRY</h5>
        <ul>
          <li>Hot and cold water taps for Buyer’s washer</li>
          <li>Single plastic laundry tub complete with taps</li>
          <li>Waste connection for Buyer’s washer</li>
          <li>Electrical receptacles for Buyer’s washer and dryer</li>
        </ul>

        <h5>COLOUR – STYLE – PAINTING</h5>
        <ul>
          <li>
            The colour/style of the following items shall be chosen by the
            Buyer from the Builder’s samples
          </li>
          <li>
            Broadloom, vinyl flooring, interior walls, kitchen cabinets,
            bathroom vanities, bathroom tiles, exterior brick and siding.
          </li>
          <li>
            For Townhouse product all exterior selections will be
            predetermined by the Builder.
          </li>
          <li>
            For Semi detached units exterior color to be selected by first
            firm offer signed per semi.
          </li>
        </ul>

        <h5>General Information</h5>
        <ul>
          <li>
            Direct Detect monitored if required by municipality (monthly fee
            paid by Buyer)
          </li>
          <li>
            Walls and ceilings to be drywalled (as per plan) *All ceilings to
            have California style ceiling finish
          </li>
          <li>All bedrooms to have ceiling lights with wall controls</li>
          <li>Oak Hand Rail on stairs to second floor as per plan</li>
          <li>Mirrors provided in all bathrooms and powder room</li>
          <li>
            3 telephone and 2 cable rough ins (location determined by Builder)
          </li>
          <li>
            Survey of the building to be provided to Buyer for mortgage
            purpose on closing
          </li>
          <li>
            The Hot Water Tank, DWHR (if installed) is rented and not included
            in the Purchase Price (the Buyer agrees to assume the rental
            contract)
          </li>
          <li>
            New home owners benefit from One Year Warranty Protection and Two
            Year Warranty Protection against defects in work and materials,
            and Seven Year Warranty Protection against major structural
            defects (MSD)., paid by the Buyer
          </li>
        </ul>
      </div>
    </section>
  </>
  );
};

export default Condos;
