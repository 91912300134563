import React from "react";
import { useEffect, useRef,useState } from "react";
import "./web.css";

import logo from "./../../images/new-website/doon-logo.svg";
import logo2 from "./../../images/new-website/white-logo.svg";
import banner from "./../../images/new-website/banner-img.webp";
import community from "./../../images/new-website/family-pic.webp";
import home from "./../../images/new-website/home.webp";
import icon1 from "./../../images/new-website/icon1.svg";
import icon2 from "./../../images/new-website/icon2.svg";
import icon3 from "./../../images/new-website/icon3.svg";
import icon4 from "./../../images/new-website/icon4.svg";
import map from "./../../images/new-website/map.webp";
import map1 from "./../../images/new-website/map1.webp";
import map2 from "./../../images/new-website/map2.webp";
import map3 from "./../../images/new-website/map3.webp";
import map4 from "./../../images/new-website/map4.webp";
import Fiona_page from "./../../images/new-website/Fiona_page.jpg";
import Preston_page from "./../../images/new-website/Preston_page.jpg";
import Niamh_page from "./../../images/new-website/Niamh_page.jpg";
import Zenith_page from "./../../images/new-website/Zenith_page.jpg";
import bigmap from "./../../images/new-website/big_map.webp";
import logo01 from "./../../images/new-website/logo01.svg";
import logo02 from "./../../images/new-website/logo02.svg";
import logo03 from "./../../images/new-website/logo03.svg";
import logo04 from "./../../images/new-website/logo04.svg";
import tarion from "./../../images/new-website/tarion.webp";
import centerimg from "./../../images/new-website/center-img.webp";
import doonlogo from "./../../images/new-website/DOON-TRAILS.svg";
import whiteract from "./../../images/new-website/white-ractangle.svg";
import warranty from "./../../images/new-website/warranty.webp";
import ContactUsDoon from "./ContactUsDoon";

const DoonWebsite = () => {
  const headerRef = useRef(null);
  const [menu,setMenu] = useState('Community')
  useEffect(() => {
    const toggleHeaderClass = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add("scrolled");
      } else {
        headerRef.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", toggleHeaderClass);

    return () => {
      window.removeEventListener("scroll", toggleHeaderClass);
    };
  }, []);

  const communityRef = useRef(null);
  const locationRef = useRef(null);
  const floorRef = useRef(null);
  const siteRef = useRef(null);
  const builderRef = useRef(null);
  const warrantyRef = useRef(null);
  const openRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (ref,name) => {
    const offset = 100;
    const sectionPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
    setMenu(name)
  };

  return (
    <>
      <section className="header_web" id="web_nav" ref={headerRef}>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">

              <img src={logo2} alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarWeb"
              aria-controls="navbarWeb"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarWeb">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a
                    
                    className={`nav-link ${menu === "Community" ? "activeMenu" : ""}`}
                    href="#COMMUNITY"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(communityRef,'Community');
                    }}
                  >
                    Community
                  </a>
                </li>
                <li className="nav-item">
                  <a
                   className={`nav-link ${menu === "Location" ? "activeMenu" : ""}`}
                    href="#Location"
                    onClick={(e) => {
                      e.preventDefault();
     
                      handleScroll(locationRef,'Location');
                    }}
                  >
                    Location
                  </a>
                </li>
                <li className="nav-item">
                  <a
                   className={`nav-link ${menu === "Floor" ? "activeMenu" : ""}`} 
                    href="#Floor"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(floorRef,"Floor");
                    }}
                  >
                    Floor Plan
                  </a>
                </li>
                <li className="nav-item">
                  <a
                  className={`nav-link ${menu === "Site" ? "activeMenu" : ""}`} 
                    href="#Site"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(siteRef,"Site");
                    }}
                  >
                    Site Map
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${menu === "Builder" ? "activeMenu" : ""}`} 
                    href="#Builder"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(builderRef,"Builder");
                    }}
                  >
                    Builder
                  </a>
                </li>
                <li className="nav-item">
                  <a
                     className={`nav-link ${menu === "Warranty" ? "activeMenu" : ""}`} 
                    href="#Warranty"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(warrantyRef,"Warranty");
                    }}
                  >
                    Warranty
                  </a>
                </li>
                <li className="nav-item">
                  <a
                      className={`nav-link ${menu === "Open" ? "activeMenu" : ""}`} 
                    href="#Open"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(openRef,"Open");
                    }}
                  >
                    Open House
                  </a>
                </li>
                <li className="nav-item">
                  <a
                     className={`nav-link ${menu === "Contact" ? "activeMenu" : ""}`} 
                    href="#Contact"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(contactRef,"Contact");
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>

      <section className="web_banner  pt-0">
        <img className="w-100" src={banner} alt="banner" />
        <div className="web_banner_text">
          <div className="container">
            <img src={logo} alt="banner" className="doon_logo" />
            <h2>
              Experience the unparalleled <br />
              charm of Doon Trails
            </h2>
            {/* <div className="web_right_img">
              <img src={logo} alt="banner" />
            </div> */}
          </div>
        </div>
      </section>

      <section
        className="community_web sec-padd-t"
        id="COMMUNITY"
        ref={communityRef}
      >
        <div className="container">
          <div className="row g-4">
            <div className="col-md-4">
              <h4 className="title_web">THE COMMUNITY</h4>
              <h2 className="heading_web">DOON TRAILS</h2>
              <p>
                Discover the charm of Doon’s Trails, the heart of Kitchener’s
                most vibrant neighborhood. Nestled in the serene southern end of
                Doon, this community boasts lush parks, scenictrails, and a
                prime location just minutes from the 401. Our
                thoughtfully-designed homes offer spacious interiors with modern
                finishes, creating a true oasis for every stage of life. Whether
                you’re a young professional, a growing family, or looking to
                downsize, you’ll love the perfect blend of natural beauty and
                contemporary living. Experience the best of city life surrounded
                by nature in Kitchener West’s premier location.
              </p>
            </div>
            <div className="col-md-8">
              <div className="commu_web_img">
                <img src={community} alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home_web">
        <img className="w-100" src={home} alt="banner" />
      </section>

      <section className="sec-padd web_ame">
        <div className="container">
          <h2 className="heading2_web">Amenities</h2>
          <div className="row g-md-5 g-4">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="icon_main_web">
                <div className="icon_web">
                  <div>
                    <img src={icon1} alt="icon" />
                  </div>
                  <h3>School</h3>
                </div>
                <p>
                  Groh Public School Sur Dhaara Music Academy JW Gerth Public
                  School Carmel New Church School Brigadoon Public School Huron
                  Heights Secondary School St Mary’s High School St Kateri
                  Tekakwitha Catholic Elementary School
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="icon_main_web">
                <div className="icon_web">
                  <div>
                    <img src={icon2} alt="icon" />
                  </div>
                  <h3>Shopping</h3>
                </div>
                <p>
                  Fairview Park Mall Sunrise Shopping Centre Deer Ridge Centre
                  Sportsworld Crossing Nike Factory Shoe Princess Auto
                  Motherhood Maternity Mark’s
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="icon_main_web">
                <div className="icon_web">
                  <div>
                    <img src={icon3} alt="icon" />
                  </div>
                  <h3>Dining</h3>
                </div>
                <p>
                  Pizzeria La Terrazza House Of Patties Edelweiss Tavern 1001
                  Nights Shawarma The Broken Egg The Busy Locavore Del’s Enoteca
                  Pizzeria Nostra Cucina McDonald’s
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="icon_main_web">
                <div className="icon_web">
                  <div>
                    <img src={icon4} alt="icon" />
                  </div>
                  <h3>Services</h3>
                </div>
                <p>Laurentian Dental Centre Belgage Medical Arts</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="web_map" id="Location" ref={locationRef}>
        <div className="container">
          <div className="row g-0 align-items-center justify-content-between">
            <div className="col-md-7">
              <img className="w-100" src={map} alt="banner" />
            </div>
            <div className="col-md-4">
              <h4 className="title_web">ABOUT THE</h4>
              <h2 className="heading_web">LOCATION</h2>
              <p>
                Discover the charm of Doon’s Trails, the heart of Kitchener’s
                most vibrant neighborhood. Nestled in the serene southern end of
                Doon, this community boasts lush parks, scenictrails, and a
                prime location just minutes from the 401. Our
                thoughtfully-designed homes offer spacious interiors with modern
                finishes, creating a true oasis for every stage of life. Whether
                you’re a young professional, a growing family, or looking to
                downsize, you’ll love the perfect blend of natural beauty and
                contemporary living. Experience the best of city life surrounded
                by nature in Kitchener West’s premier location.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd web_floor" id="Floor" ref={floorRef}>
        <div className="container">
          <h2 className="heading2_web">Floor Plans</h2>
          <div className="row g-4">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="map_sec_div">
                <img src={Zenith_page} alt="icon" />
                <a href="https://drive.google.com/file/d/1L2lP_m1PPajwFat33AOwQZgRrVlBuHpV/view"
                  target="_blank"
                className="btn_web">
                  Download
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="map_sec_div">
                <img src={Niamh_page} alt="icon" />
                <a href="https://drive.google.com/file/d/180JrTZCOoWv39QKmWblsZbT9y_pk2LTg/view"
                  target="_blank"
                className="btn_web">
                  Download
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="map_sec_div">
                <img src={Preston_page} alt="icon" />
                <a href="https://drive.google.com/file/d/1s1o3GbpAuBupDlINWiI3w2j2GI3nCbPS/view"
                  target="_blank"
                  className="btn_web">
                  Download
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="map_sec_div">
                <img src={Fiona_page} alt="icon" />
                <a
                  href="https://drive.google.com/file/d/1N2dThx8idpYe8k3IHBNeLQ6CggJcZt0E/view"
                  target="_blank"
                  className="btn_web"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd site_web" id="Site" ref={siteRef}>
        <div className="container">
          <h2 className="heading2_web">Site Plan</h2>
          <p>
            Discover Doon’s Trails, where exceptional open-concept two and
            three-bedroom stacked flats are designed to suit every stage of
            life. Nestled in family-friendly neighborhoods and surrounded by
            lush, environmentally-preserved green spaces, this community
            perfectly blends urban convenience with serene natural beauty. Enjoy
            the best of both worlds with proximity to city amenities and miles
            of scenic trails for endless exploration. Embrace our motto: Life
            Outside, in the City! Experience the unparalleled charm of Doon’s
            Trails today!
          </p>
        </div>
      </section>
      <section className="bigmap_web">
        <img className="w-100" src={bigmap} alt="banner" />
      </section>

      <section className="sec-padd builder_web" id="Builder" ref={builderRef}>
        <div className="container">
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-6">
              <img className="w-100" src={logo2} alt="banner" />
            </div>
            <div className="col-md-6">
              <h2 className="heading2_web pb-5">The Builder</h2>
              <p>
                At Crescent Homes, we believe in enhancing the quality of life.
                We offer prime locations, innovative products, excellent
                customer service, and competitive pricing. A qualified team
                meticulously crafting every home, ensuring attention to detail
                and customer satisfaction. We prioritize energy efficiency,
                health, and comfort in our homes, tailoring each build to meet
                the unique needs of every buyer. Crescent Homes' commitment
                extends beyond construction; we strive to foster trust, build
                strong relationships, and attain peace of mind for our buyers.
              </p>
              <p>
                Our knowledgeable and passionate team collaborates closely with
                buyers throughout the process to make their vision a reality. We
                strive to uphold the values of innovation, integrity, and
                excellence in everything we do, providing employees with a
                supportive work environment in which they can deliver
                exceptional craftsmanship and service while maintaining
                professional integrity.
              </p>
              <p>
                Our mission is to provide customers with high-quality,
                dependable homes that they will be proud of for years. We value
                professionalism, integrity, and fairness in all of our
                interactions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd project_web">
        <div className="container">
          <h4 className="pb-5 mb-3">More Projects by Crescent Homes</h4>
          <div className="row g-md-5 g-4 align-items-center justify-content-between">
            <div className="col-lg-3 col-md-4 col-5">
              <img src={logo01} alt="banner" />
            </div>
            <div className="col-lg-3 col-md-4 col-5">
              <img src={logo02} alt="banner" />
            </div>
            <div className="col-lg-3 col-md-4 col-5">
              <img className="mb-45" src={logo03} alt="banner" />
            </div>
            <div className="col-lg-3 col-md-4 col-5">
              <img src={logo04} alt="banner" />
            </div>
          </div>
        </div>
      </section>

      <section
        className="sec-padd warranty_web"
        id="Warranty"
        ref={warrantyRef}
      >
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-md-6 m-show">
              <img className="w-100 h-auto" src={warranty} alt="banner" />
            </div>

            <div className="col-md-6">
              <img src={tarion} alt="banner" />
            </div>
            <div className="col-md-6">
              <h5>Crescent Homes</h5>
              <h2 className="heading_web">WARRANTY</h2>
              <p>
                For your peace of mind your home is protected by TARION Warranty
                Corporation for a period of 7 years against majo structural
                defects Comprehensive full one year service warranty provided by
                the Builder, backed by TARION Warranty Corporation In accordance
                with standard building practice and the TARION Warranty
                Corporation rules, the Builder warrants to make any necessary
                drywall repairs (due to nail pops and drywall cracks caused by
                settling) at year end, but the priming and painting of these
                repairs will be the full responsibility of the Purchaser,
                regardless of whether the Builder or Purchaser painted the house
                initially Purchaser will reimburse the Builder for the cost of
                the Warranty fee as an adjustment on closing,
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-padd center_web" id="Open" ref={openRef}>
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-md-6">
              <div className="flex-end-web">
                <div className="center_web_main">
                  <img src={doonlogo} alt="banner" />
                  <h2>Sales Centre</h2>
                  <h4>OPEN EVERY</h4>
                  <p className="open-house">Saturday & Sunday • 12:00 to 4:00PM</p>
                  <h5>277 Chapel Hill Drive, Kitchener</h5>
                </div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2899.9123380928877!2d-80.45345062397449!3d43.37885697111643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c754c471ba79d%3A0xf4238e5e4038753d!2s277%20Chapel%20Hill%20Dr%2C%20Kitchener%2C%20ON%20N2R%201N3%2C%20Canada!5e0!3m2!1sen!2sin!4v1730634586522!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <img className="w-100" src={centerimg} alt="banner" />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="sec-padd form_web" id="Contact" ref={contactRef}>
        <div className="container">
          <div className="row g-md-5 g-4">
            <div className="col-md-8 m-auto">
              <h2>Request a Call Back</h2>

              <form>
                <div className="row g-4">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name=""
                      id=""
                      rows="3"
                      placeholder="Your Message"
                    ></textarea>
                  </div>

                  <div className="col-md-12">
                    <h4>Are you currently working with a Realtor?</h4>
                    <div className="web-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="yes_w"
                        id=""
                      />
                      <label className="form-check-label" htmlFor="">
                        Yes
                      </label>
                    </div>
                    <div className="web-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="yes_w"
                        id=""
                      />
                      <label className="form-check-label" htmlFor="">
                        No
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <h4>Are you a realtor?</h4>
                    <div className="web-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="yes_m"
                        id=""
                      />
                      <label className="form-check-label" htmlFor="">
                        Yes
                      </label>
                    </div>
                    <div className="web-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="yes_wm"
                        id=""
                      />
                      <label className="form-check-label" htmlFor="">
                        No
                      </label>
                    </div>
                  </div>
                  <div>
                    <input type="submit" value="Submit Now" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      <ContactUsDoon contactRef={contactRef} id="Contact"/>

      <section className="web_footer">
        <div className="container">
          <div className="row g-md-5 g-4 align-items-center">
            <div className="col-md-4">
              <img src={whiteract} alt="logo" />
            </div>
            <div className="col-md-4">
              <h3>REACH US</h3>
              <p>sales@crescenthomes.ca</p>
              <h3>548-255-6105</h3>
            </div>
            <div className="col-md-4">
            <a  href="/">
              <img src={logo2} alt="logo" style={{cursor:'pointer'}} />
              </a>
            </div>
          </div>
          <ul>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#">Terms of Use</a>
            </li>
            <li>
              <a href="#">Sales and Refunds</a>
            </li>
            <li>
              <a href="#">Legal</a>
            </li>
            <li>
              <a href="#">Site Map</a>
            </li>
          </ul>
        </div>
      </section>
      {/* <section className="web_footer_n">
        <div className="container">
          <p>
            Base house prices include all sales taxes (HST). Rebate signed back
            to builder on closing. Prices and promotions are subject to change
            without notice. Please see sales professional for details. E. & O.
            E. Prices effective May 10, 2024
          </p>
        </div>
      </section> */}
    </>
  );
};

export default DoonWebsite;
