import React from "react";

const Rockwood = () => {
  return (
    <>
      <section className="sec-padd bg_light pt-5 content_sec">
        <div className="container">
          <h1 className="heading_c text-center">Home Builders in Rockwood</h1>
          <div className="row g-md-5 g-4">
            <div className="col-md-12">
              <p>
              Crescent Homes partners exclusively with experienced engineers, planners and developers who deliver top class workmanship. Your home is built by trusted experts.
              </p>
              <p>
              Their floor plans are crafted to maximize the space, functionality and beauty of each room. You get to decide what happens with your house. Whether you want an office, a library or a workshop, we accommodate everything you want; in your budget.

              </p>
              <p>
                By taking part in transparent correspondence from the earliest
                Crescent Homes stick to Ontario Building Code, to the tee!

              </p>
              <p>
              Crescent Homes is built on a vision to be a leader in supporting people and fostering mutual trust with it’s clients. It is committed to improve new housing affordability and choice for new home purchasers and renovators.
              </p>
              <p>
              Rockwood, Ontario, might be a small place but offers a great neighbourhood for its small population. Crescent Homes constructs houses in Ontario and tries to make this a better place for one and all. You get a chance to get a tailor fit home to cater to all your requirements, no matter how eccentric.
              </p>
              <p>
              You can start by visiting our fully decorated models. Tour the home and view floor plans, as many times as you like. And have your questions answered by our friendly staff.
              </p>
              <p>
              For more details visit crescenthomes.ca
              </p>
              <p>
              Have a good day!

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rockwood;
