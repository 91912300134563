import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { useTheme } from "@emotion/react";

// Validation schema
const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),

  mobile: yup
    .string()
    .matches(/^\d+$/, "Only numbers are allowed.")
    .min(10, "Mobile number should be of minimum 10 digits.")
    .required("Mobile is required."),
  // mobile: yup
  // .string()
  // .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits.")
  // .required("Mobile is required."),
  budget: yup.string().required("Budget is required"),
  userType: yup.string().required("User type is required"),
  // beds: yup.string().required("Number of beds is required"),
  // baths: yup.string().required("Number of baths is required"),
  beds: yup
    .number()
    .typeError("Number of beds must be a number")
    .required("Number of beds is required")
    .positive("Number of beds must be a positive number")
    .integer("Number of beds must be an integer"),
  baths: yup
    .number()
    .typeError("Number of baths must be a number")
    .required("Number of baths is required")
    .positive("Number of baths must be a positive number")
    .integer("Number of baths must be an integer"),
  propertyType: yup.string().required("Property type is required"),
  message: yup.string().required("Message is required"),
});

const ContactUs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      budget: "",
      userType: "agent",
      beds: "",
      baths: "",
      propertyType: "condo",
     // subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let url = `${endPoints.api.EMAIL_SUPPORT}`;
      let data = {
        fullName: `${values.firstName} ${values.lastName}`,
        email: values.email,
        subject: "",
        description: values.message,
        propertyType: values.propertyType,
        userType: values.userType,
        budget: values.budget,
        beds: values.beds,
        baths: values.baths,



      };
      dispatch(setLoading(true));
      await addData(url, data)
        .then((response) => {
          dispatch(setLoading(false));
          if (response.data.status === "success") {
            showSuccessSnackbar("Thank you for contacting us.");
            resetForm();
          } else {
            showErrorSnackbar(response.data.message);
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.error(error);
        });
    },
  });

  return (
    <>
      <section className="banner_sec contact_banner">
        {/* Banner code omitted for brevity */}
      </section>

      <section className="sec-padd content_sec contact_form">
        <Container>
          <div className="col-md-8 m-auto">
            <p>
              Interested in learning more about the Crescent Homes sales process
              or have a homebuyer question that isn't covered online? We'd love
              to hear from you. Contact us at +1 647-523-6487 or via the form
              below.
            </p>

            <form onSubmit={formik.handleSubmit}>
              <Typography variant="subtitle1" align="left">
                Information
              </Typography>
              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.userType && Boolean(formik.errors.userType)
                    }
                  >
                    <Select
                      id="userType"
                      name="userType"
                      value={formik.values.userType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{fontSize:'20px'}}
                    >
                    
                      <MenuItem value="agent" style={{ fontSize: '20px' }}>I'm a real estate agent</MenuItem>
                      <MenuItem value="owner" style={{ fontSize: '20px' }}>I'm a property owner</MenuItem>
                    </Select>
                    {formik.touched.userType && formik.errors.userType && (
                      <Typography color="error">
                        {formik.errors.userType}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                </Grid>
              </Grid>

              <Typography variant="subtitle1" align="left">
                Property
              </Typography>

              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.propertyType &&
                      Boolean(formik.errors.propertyType)
                    }
                  >
                    <Select
                      id="propertyType"
                      name="propertyType"
                      value={formik.values.propertyType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{fontSize:'20px'}}
                    >
                      <MenuItem value="condo" style={{ fontSize: '20px' }}>Condo Towns</MenuItem>
                      <MenuItem value="luxury_villa" style={{ fontSize: '20px' }}>
                        Large Luxury Villa
                      </MenuItem>
                      <MenuItem value="singlefamily" style={{ fontSize: '20px' }}>
                        Single Family Home
                      </MenuItem>
                      <MenuItem value="singletown" style={{ fontSize: '20px' }}>Singles and Towns</MenuItem>
                      <MenuItem value="singletownandcondo" style={{ fontSize: '20px' }}>
                        Singles, Towns, and Condo Towns
                      </MenuItem>
                      <MenuItem value="modernvilla" style={{ fontSize: '20px' }}>
                        Standard Modern Villa
                      </MenuItem>
                      <MenuItem value="superior" style={{ fontSize: '20px' }}>Superior Villa</MenuItem>
                      <MenuItem value="townandcondo" style={{ fontSize: '20px' }}>
                        Town & Condo Towns
                      </MenuItem>
                    </Select>
                    {formik.touched.propertyType &&
                      formik.errors.propertyType && (
                        <Typography color="error">
                          {formik.errors.propertyType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="budget"
                    name="budget"
                    placeholder="Your Budget"
                    value={formik.values.budget}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.budget && Boolean(formik.errors.budget)
                    }
                    helperText={formik.touched.budget && formik.errors.budget}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "16px" }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="beds"
                    name="beds"
                    placeholder="Number of beds"
                    value={formik.values.beds}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.beds && Boolean(formik.errors.beds)}
                    helperText={formik.touched.beds && formik.errors.beds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="baths"
                    name="baths"
                    placeholder="Number of baths"
                    value={formik.values.baths}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.baths && Boolean(formik.errors.baths)}
                    helperText={formik.touched.baths && formik.errors.baths}
                  />
                </Grid>
              </Grid>

              <div>
                <Typography variant="subtitle1" align="left">
                  Message
                </Typography>
                <TextField
                  fullWidth
                   multiline
                  rows={4}
                  id="message"
                  name="message"
                  placeholder="Enter your message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ width: "100%", marginTop: "20px" }}
              >
                Submit
              </Button>
            </form>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
