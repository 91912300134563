import React from "react";

function WarrantyCoverage() {
  return (
    <>
      
      <section className="banner_sec about_banner">
        <div className="container">
          <div className="inner_banner_text">
            <h1>Warranty Coverage</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/">
                  <span>Home </span>
                </a>
              </li>
              <li>&gt;</li>
              <li>Warranty Coverage</li>
            </ol>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </section>

      <section className="sec-padd bg_light pt-5 content_sec">
        <div className="container">
          <h1 className="heading_c text-center">
            Built to Last, Family driven Values and Exceptional service
          </h1>
          <div className="row g-md-5 g-4">
            <div className="col-md-6">
              <h3>Warranty Coverage</h3>
              <p>
                Crescent Homes is a registered builder under Tarion and our goal
                is provide the warranties on the product that our customer is
                buying, making sure your investment in our product is
                safeguarded.
              </p>
              <p>
                Your Warranty Certificate was given to you at the time of your
                Pre-Delivery Inspection during which you listed any deficiencies
                in your home prior to moving in.
              </p>
              <p>
                We make every effort to correct any noted deficiencies within
                the first few weeks, and will revisit you to have the form
                signed, which signifies your acceptance of our
                completions/repairs. It is in your interest to have this form
                fully completed since it will have to be processed through our
                office and sent to Tarion.
              </p>

              <h3>1st Year Warranty Coverage</h3>
              <p>
                Crescent Homes warrants your home against defects in workmanship
                and material for a period of ONE YEAR starting on your Closing
                Day. You will have TWO (2) formal opportunities to request
                Warranty Service in your first year of occupancy:
              </p>

              <ul>
                <li>During the first 30 days of occupancy;</li>
                <li>During the last 30 days of the first year of occupancy.</li>
              </ul>
              <p>
                <a href="https://myhome.tarion.com." target="_blank">
                  https://myhome.tarion.com.
                </a>
              </p>
              <p>
                To register online, you will need your Certificate of Completion
                & Closing (CCP form), which we provide to you at your PDI. For
                further instructions on how to register for Tarion’s MyHome
                Portal, please click the link above.
              </p>
              <p>
                Upon receiving your Warranty Service Request we will contact you
                to schedule one or more service appointments. In order to
                complete the warranty work in your home, Tarion has established
                a CUSTOMER SERVICE STANDARD for Builders in Ontario. By adhering
                to this Standard, Crescent Homes will ensure that warranted
                items are being addressed within a reasonable time frame, while
                balancing your needs with the needs of our other Homeowners.
              </p>
              <p>
                All questions relating to appointment scheduling, the nature of
                work being performed, or the status of repairs should be
                directed to your Warranty Service Administrator. Tarion allows a
                period of 120 days to address the items listed on a statutory
                warranty form and requires Homeowners to permit access to the
                home during regular business hours to perform repairs. If you
                are not satisfied with the condition of a repair following
                service, please contact our Warranty Service Office and we will
                gladly review the repair of the item.
              </p>
            </div>

            <div className="col-md-6">
              <h3>2nd Year Warranty Coverage</h3>
              <p>
                The following items are warranted against defects in workmanship
                and material for a period of TWO YEARS starting on your Closing
                Day:
              </p>
              <ul>
                <li>Plumbing system</li>
                <li>Heating system</li>
                <li>Electrical delivery and distribution systems,</li>
              </ul>
              <p>
                If you experience a problem with any of the above-noted items
                during your first two years of occupancy, please report it to us
                immediately, as these generally constitute an “emergency”
                repair.
              </p>
            </div>
          </div>

          <div className="row g-md-5 g-4 mt-5 pb-5">
            <div className="col-md-12">
              <h2 className="pb-5">
                Summary Of Items Not Covered Under Warranty
              </h2>

              <ol className="list_half">
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
                <li>Locating survey monuments and boundary lines.</li>
                <li>Winterkill in lawns.</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WarrantyCoverage;
