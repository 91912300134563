import * as React from "react";
import logo from "../../images/logo3.svg";
import "./../../../src/App.css"; // Import your custom CSS file

function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo"
              src={logo}
              alt="logo"
              style={{ height: "70px", marginLeft: "10px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto main-header">
              <li className="nav-item hover-highlight">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item hover-highlight">
                <a className="nav-link" href="/about-us">
                  About
                </a>
              </li>
              <li className="nav-item dropdown hover-highlight">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/single-detached">
                      Single Detached
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/semi-detached">
                      Semi Detached
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="/town-homes">
                      Townhomes
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/stack-town-home">
                      Stacked Townhomes
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/condos">
                      Condos
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item hover-highlight">
                <a className="nav-link" href="/communities">
                  Communities
                </a>
              </li>
              <li className="nav-item hover-highlight">
                <a className="nav-link" href="/gallery">
                  Our Gallery
                </a>
              </li>
              <li className="nav-item hover-highlight">
                <a className="nav-link" href="social">
                  Social Responsibility
                </a>
              </li>
              <li className="nav-item dropdown hover-highlight">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Home Owners
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/warranty-coverage">
                      Warranty Coverage
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/useful-forms">
                      Useful Forms
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item hover-highlight">
                <a className="nav-link" href="/contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
