import React from "react";
import { Link } from "react-router-dom";
import Capture4 from "../../images/Capture4-1.jpg";
import logokidsability from "../../images/logo-kidsability@2x-1.png";
import Capture3 from "../../images/Capture3-1.jpg";
import Capture2 from "../../images/Capture2-1.jpg";
import Capture11 from "../../images/Capture1-1.jpg";
import Capture1 from "../../images/Capture-1.jpg";
import logo1 from "../../images/logo-1-1.png";


const Social = () => {
  return (
    <>
  <section className="banner_sec about_banner social_sec">
        <div className="container">
          <div className="inner_banner_text">
            <h1>Social Responsibility</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/"><span>Home   </span></a>
              </li>
              <li>&gt;</li>
              <li>Social Responsibility</li>
            </ol>

          </div>
        </div>
        <div className="elementor-shape elementor-shape-bottom" data-negative="true">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path className="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
          </svg>		</div>
      </section>

      <section className="sec-padd bg_light social_page content_sec">
        <div className="container">
          <div className="col-md-8 m-auto">
          <h1 className="heading_2"> PROSPERITY WITH A PURPOSE  </h1>
          <h2 className="heading_3">Giving back moves us all forward</h2>
          <p>The Crescent Homes team proudly supports the following groups and charities with our time and resources:

</p>
<img src="https://1.bp.blogspot.com/-eCBQq3nCeTg/YEyXgwAF3aI/AAAAAAAA74o/MpoP9NItgusAATKSvKRLIFigv_M6S0T3gCLcBGAsYHQ/s320/ray-of-hope.png"></img>
<h3 className="heading_4">RAY OF HOPE COMMUNITY CENTRE </h3>
<p>Crescent Homes supports the Ray of Hope Communities Centre
(ROHCC) in fundraising, volunteering and construction of their new facility.
We encourage you to visit their website to see a variety of opportunities to
get yourself, family or business involved.</p>
<div className="link_group">
  <Link to="http://rayofhope.net/" target="_blank">rayofhope.net</Link>
       |
       <Link to="http://morningglorycafe.net/" target="_blank">morningglorycafe.net</Link>
</div>
          </div>
          </div>
          </section>
          <section className="sec-padd bg_light pt-5 content_sec">
          <div className="container">
          <div className="col-md-8 m-auto">
            <div className="row g-md-5 g-4">
              <div className="col-md-4 col-sm-6 col-6">
                <img src={Capture4}/>
                 </div>

                 <div className="col-md-4 col-sm-6 col-6">
                <img src={logokidsability}/>
                 </div>
                 <div className="col-md-4 col-sm-6 col-6">
                <img src={Capture3}/>
                 </div>
                 <div className="col-md-4 col-sm-6 col-6">
                <img src={Capture2}/>
                 </div>
                 <div className="col-md-4 col-sm-6 col-6">
                <img src={Capture11} />
                 </div>
                 <div className="col-md-4 col-sm-6 col-6">
                <img src={Capture1}/>
                 </div>
                 <div className="col-md-4 col-sm-6 col-6">
                <img src={logo1}/>
                 </div>

            </div>
            </div>
            </div>
            </section>
    </>
  );
};

export default Social;
