import React, { useState, useEffect } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./Theme";

import RouteList from "./RouteList";
import { useSelector } from "react-redux";
import Loader from "./component/Loader";
import { axiosInstance } from "./Utility/axiosInstances";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { setThemeMode } from "./redux/Reducers/GlobalReducer/globalSlice";
import { setLoginData } from "./redux/Reducers/AuthReducer/authSplice";
import { setIsDrawerOpen } from "./redux/Reducers/sidebarReducer/sidebarSlice";

function App() {
  const globalState = useSelector((state) => state.global);
  const { themeMode, loading } = globalState;
  const theme = themeMode === "light" ? lightTheme : darkTheme;
  const history = createBrowserHistory();
  const authState = useSelector((state) => state.auth);
  const { IsLoginData } = authState;
  const dispatch = useDispatch();

  axiosInstance.interceptors.request.use((config) => {
    // config.headers["SessonId"] = IsLoginData.sessionId;
    config.headers["SessonId"] = localStorage.getItem("session");
    return config;
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log("error", error);
      if (error.response.status == "401") {
        dispatch(setLoginData({ user: {}, isAuthenticated: false }));
        window.alert(
          "You are currently logged in on another device. Please login again to continue.  You will be logged out of your other device."
        );
        dispatch(setThemeMode("dark"));

        history.replace("/login");
        // window.location.reload();
      }
      return error;
    }
  );

  useEffect(() => {
    dispatch(setIsDrawerOpen(true));
  }, []);

  return (
    // <ThemeProvider theme={theme}>
    <div>
      <CssBaseline />
      <Loader loading={loading} />

      <RouteList />
    </div>

    // </ThemeProvider>
  );
}

export default App;
