import React from "react";

const Cambridge = () => {
  return (
    <>
      <section className="sec-padd bg_light pt-5 content_sec">
        <div className="container">
          <h1 className="heading_c text-center">Home Builders In Cambridge</h1>
          <div className="row g-md-5 g-4">
            <div className="col-md-12">
              <p>
                Crescent Homes is recognized as one of the best construction
                management firms in Canada. They have been able to make a mark
                for themselves because of their dedicated work in the past few
                decades. We’ve remained a notch above our opposition because of
                our excellent team work and our development administration
                contract. Our staff is a characteristic fit and genuinely do try
                to comprehend and address the issues of our customers. Crescent
                Homes corporate society bolsters this group approach so staff
                interface with outline advisors, suppliers, producers, and
                customers to guarantee we persistently convey value added
                services.
              </p>
              <p>
                In the outline/build domain, Crescent Homes gives a solitary
                wellspring of obligation to the client. They work to create and
                comprehend venture desires and after that deal with these needs
                with a qualified configuration group. With respectability,
                trust, and duty to connections at the bleeding edge of our
                outline construct ventures, they have the demonstrated capacity
                to work faithfully as configuration developers. Since beginning,
                Crescent Homes has worked all through Cambridge, broadly, and
                universally as an effective configuration manufacturer.
              </p>
              <p>
                By taking part in transparent correspondence from the earliest
                starting point, the Crescent Homes group guarantees no curve
                balls, working together with customers to secure an intensive
                and illustrative venture scope. From this position, the
                undertaking group is balanced for on-time, on-spending plan,
                beyond your expectations building.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cambridge;
