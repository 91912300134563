import React, { useState } from "react";
import { ThemeProvider, CssBaseline, Container, Button } from "@mui/material";
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { darkTheme, lightTheme } from "./Theme";

import { useSelector } from "react-redux";
import ContactUs from "./screens/ContactUs";
import About from "./screens/AboutUs";
import { createBrowserHistory } from "history";
import ScrollToTop from "./component/ScrollToTop";

import Home from "./screens/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Social from "./screens/Social";
import Community from "./screens/Community";
import Gallery from "./screens/Gallery";
import Condos from "./screens/Condos";
import SemiDetached from "./screens/SemiDetached";
import StackTowmHome from "./screens/StackTownHome";
import TowmHomes from "./screens/TownHomes";
import SingleDetached from "./screens/ SingleDetached";
import CommunityDetails from "./screens/Community/CommunityDetails";
import UsefulForm from "./screens/UsefulForms";
import WarrantyCoverage from "./screens/WarrantyCoverage";
import NestedPage from "./screens/NestedPage";
import DoonWebsite from "./screens/DoonWebsite";
import SouthCreekWebsite from "./screens/SouthCreekWebsite";
import OtterbeinWoodsWebsite from "./screens/OtterbeinWoodsWebsite";
import Waterloo from "./screens/Waterloo";
import Cambridge from "./screens/Cambridge";
import Kitchener from "./screens/Kitch";
import Rockwood from "./screens/Rockwood";
import Gwillimbury from "./screens/Gwillimbury";
import Brant from "./screens/Brant";

function RouteList() {
  const pathname = window.location.pathname;
  console.log("pathname", pathname);
  return (
    <BrowserRouter>
      {pathname === "/communities/doon-trails" ||
      pathname === "/communities/otterbein-woods" ||
      pathname === "/communities/south-creek" ? null : (
        <Header />
      )}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/social" element={<Social />} />
        <Route exact path="/communities" element={<Community />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/condos" element={<Condos />} />
        <Route exact path="/single-detached" element={<SingleDetached />} />
        <Route exact path="/town-homes" element={<TowmHomes />} />
        <Route exact path="/stack-town-home" element={<StackTowmHome />} />
        <Route exact path="/semi-detached" element={<SemiDetached />} />
        <Route exact path="/useful-forms" element={<UsefulForm />} />
        <Route exact path="/warranty-coverage" element={<WarrantyCoverage />} />
        {/* <Route exact path="community/:category" element={<NestedPage />} /> */}
        <Route exact path="communities/doon-trails" element={<DoonWebsite />} />
        <Route
          exact
          path="communities/south-creek"
          element={<SouthCreekWebsite />}
        />
        <Route
          exact
          path="communities/otterbein-woods"
          element={<OtterbeinWoodsWebsite />}
        />
        <Route exact path="/home-builders-in-waterloo" element={<Waterloo />} />
        <Route exact path="/home-builders-in-cambridge" element={<Cambridge />} />
        <Route exact path="/home-builders-in-kitchener" element={<Kitchener />} />
        <Route exact path="/home-builders-in-rockwood" element={<Rockwood />} />
        <Route exact path="/home-builders-in-bradford-west-gwillimbury" element={<Gwillimbury />} />
        <Route exact path="/home-builder-in-brant" element={<Brant />} />

     
      </Routes>

      {pathname === "/communities/doon-trails" ||
      pathname === "/communities/south-creek" ||
      pathname === "/communities/otterbein-woods" ? null : (
        <Footer />
      )}
    </BrowserRouter>
  );
}

export default RouteList;
