import React from "react";
import { useEffect, useRef } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import "./otter-web.css";
import logo from "./../../images/otterbeinwoods/otterbeinwoods-logo.webp";
import banner from "./../../images/otterbeinwoods/BlockBPerspective4_web.webp";
import icontop from "./../../images/otterbeinwoods/figure97.webp";
import imgbottom from "./../../images/otterbeinwoods/slider_bg_1.webp";
import iconbottom from "./../../images/otterbeinwoods/slider_bg_2.webp";
import logo2 from "./../../images/otterbeinwoods/cropped-logo.png";
import banner2 from "./../../images/otterbeinwoods/building.webp";
import img1 from "./../../images/otterbeinwoods/Capture.webp";
import img2 from "./../../images/otterbeinwoods/Capture2.webp";
import img3 from "./../../images/otterbeinwoods/Capture3.webp";
import img4 from "./../../images/otterbeinwoods/Creek-B.webp";
import img5 from "./../../images/otterbeinwoods/Capture5.webp";
import img6 from "./../../images/otterbeinwoods/Capture6.webp";
import man1 from "./../../images/otterbeinwoods/agent.webp";
import man2 from "./../../images/otterbeinwoods/Ashish.webp";
import ContactUsWoods from "./ContactUsWoods";


const OtterbeinWoodsWebsite = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    const toggleHeaderClass = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add("scrolled");
      } else {
        headerRef.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", toggleHeaderClass);

    return () => {
      window.removeEventListener("scroll", toggleHeaderClass);
    };
  }, []);

  const STANDARDRef = useRef(null);
  const WARRANTYRef = useRef(null);
  const floorRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (ref) => {
    const offset = 100;
    const sectionPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
  };

  return (
    <>
      <main id="oterbein">
        <header ref={headerRef}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src={logo} alt="logo" />
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item"><a className="nav-link"  href="#STANDARD"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(STANDARDRef);
                    }}>Standard Features</a></li>
                  <li className="nav-item"><a className="nav-link"  href="#WARRANTY"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(WARRANTYRef);
                    }}>Warranty</a></li>
                  <li className="nav-item"><a className="nav-link"  href="#floor"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(floorRef);
                    }}>Floor Plans</a></li>
                  <li className="nav-item"><a className="nav-link"  href="#contact"
                    onClick={(e) => {
                      e.preventDefault();
                      handleScroll(contactRef);
                    }}>Contact Us</a></li>
                </ul>
              </div>
            </div>
          </nav>

        </header>
        <section className="hero-section" >
          <img className="w-100" src={banner} alt="banner" />
          <img className="icon_top" src={icontop} alt="banner" />
          <img className="icon_dots" src={imgbottom} alt="banner" />
          <img className="icon_bottom" src={iconbottom} alt="banner" />

          <div className="container">
            <div className="abs_banner">
              <h5>NOW SELLING!</h5>
              <h1>URBAN TOWNS IN <br /> KITCHENER</h1>
              <p>Live where Comfort Meets Convenience</p>
              <a href="#" className="btn btn-outline-light">Contact Us <FiArrowRightCircle />

              </a>
            </div>
          </div>
        </section>

        <section className="gray_img"></section>

        <section className="features  sec-padd"  id="STANDARD"
        ref={STANDARDRef}>
          <div className="container-fluid">
            <h2 className="heading1">STANDARD FEATURES</h2>
            <div className="row g-4">
              <div className="col-md-3 cl-sm-6">
                <h5>Exterior Features</h5>
                <ul className="list-unstyled">
                  <li><FaCheckCircle /> 2x6 wall panels, R27 insulation</li>
                  <li><FaCheckCircle /> Quality clay brick</li>
                  <li><FaCheckCircle /> Premium siding installed on elevationsn</li>
                  <li><FaCheckCircle /> High efficiency Low E Argon windows</li>
                  <li><FaCheckCircle /> Insulated patio door</li>
                  <li><FaCheckCircle /> Poured concrete foundation walls and basement floors</li>

                </ul>
              </div>
              <div className="col-md-3 cl-sm-6">
                <h5>KITCHEN AND BATH</h5>
                <ul className="list-unstyled">
                  <li><FaCheckCircle /> Quality kitchen and bathroom cabinets</li>
                  <li><FaCheckCircle /> Stone countertops in kitchen</li>
                  <li><FaCheckCircle /> Opening in cabinetry for future dishwasher</li>
                  <li><FaCheckCircle /> Heavy duty receptacle for electric stove</li>
                  <li><FaCheckCircle /> Large mirrors above vanities</li>
                  <li><FaCheckCircle /> Dedicated electrical outlet for refrigerator</li>

                </ul>
              </div>
              <div className="col-md-3 cl-sm-6">
                <h5>INTERIOR FEATURES</h5>
                <ul className="list-unstyled">
                  <li><FaCheckCircle /> Impressive 8 ft. ceilings on all levels</li>
                  <li><FaCheckCircle /> Drywalled half-walls are capped in wood and painted white</li>
                  <li><FaCheckCircle /> Deadbolts on all entry doors</li>
                  <li><FaCheckCircle /> ‘California’ textured ceilings</li>
                  <li><FaCheckCircle /> Luxury vinyl plank and tile from builders selections</li>
                  <li><FaCheckCircle /> 5/8” OSB subfloors screwed to minimize squeaks</li>

                </ul>
              </div>
              <div className="col-md-3 cl-sm-6">
                <h5>ELECTRICAL & MECHANICAL</h5>
                <ul className="list-unstyled">
                  <li><FaCheckCircle /> 100 AMP service with circuit breaker panel</li>
                  <li><FaCheckCircle /> Electrical layout include one light fixture</li>
                  <li><FaCheckCircle /> Interior door chime</li>
                  <li><FaCheckCircle /> 1 exterior weather proof receptacle</li>
                  <li><FaCheckCircle /> Luxury vinyl plank and tile from builders selections</li>
                  <li><FaCheckCircle /> Central air conditioning included</li>

                </ul>
              </div>

            </div>
          </div>
        </section>

        <section className="warranty-section sec-padd "  id="WARRANTY"
        ref={WARRANTYRef}>
          <div className="container-fluid">
            <div className="row g-4">
              <div className="col-md-6">
                <div className="text-center">
                  <img src={logo2} alt="Crescent Homes Logo" />
                </div>
                <h2>CRESCENT HOMES WARRANTY</h2>
                <p>For your peace of mind your home is protected by TARION Warranty Corporation for a period of 7 years against major structural defects.

                </p>
                <p>Comprehensive full one year service warranty provided by the Builder, backed by TARION Warranty Corporation.</p>
                <p>In accordance with standard building practice and the TARION Warranty Corporation rules, the Builder warrants to make any necessary drywall repairs (due to nail pops and drywall cracks caused by settling) at year end, but the priming and painting of these repairs will be the full responsibility of the Purchaser, regardless of whether the Builder or Purchaser painted the house initially.</p>
                <p>Purchaser will reimburse the Builder for the cost of the Warranty fee and surveys an adjustment on closing.

                </p>
              </div>
              <div className="col-md-6">
                <img src={banner2} alt="Crescent Homes Logo" />

              </div>

            </div>

          </div>
        </section>

        <section className="floor-plans-section sec-padd" id="floor"
        ref={floorRef}>
          <div className="container">
            <h2>FLOOR PLANS</h2>
            <ul className="nav nav-tabs" id="floorPlanTabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">The RIVER A</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="true">The RIVER B</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="true">The CREEK A</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab4-tab" data-bs-toggle="tab" data-bs-target="#tab4" type="button" role="tab" aria-controls="tab4" aria-selected="true">The CREEK B</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab5-tab" data-bs-toggle="tab" data-bs-target="#tab5" type="button" role="tab" aria-controls="tab5" aria-selected="true">The BROOK A</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab6-tab" data-bs-toggle="tab" data-bs-target="#tab6" type="button" role="tab" aria-controls="tab6" aria-selected="true">The BROOK B</button>
              </li>

            </ul>
            <div className="tab-content mt-4" id="floorPlanContent">
              <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>A chic corner unit nestled in the lower level, spanning 858 sq. ft. This cozy home features two inviting bedrooms and a sleek bathroom.</p>
                    <p>Its open-concept living and dining area seamlessly blend into an enchanting patio space, perfect for relaxation and entertaining

                    </p>
                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">LOWER LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">858
                        SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">2</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATH</span><span class="value-list">1</span></li>
                    </ul>
                    <a href="https://drive.google.com/file/d/1g5pGgJ2sosXuGvAHIAfeNQZy9svPf9p1/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!</a>
                  </div>
                  <div className="col-md-5">
                    <img src={img1} alt="logo" />

                  </div>

                </div>

              </div>
              <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>An elegantly designed interior unit located on the lower level, offering a comfortable 817 sq. ft. of living space. This unit boasts two welcoming bedrooms and a modern bathroom, thoughtfully crafted to provide an ideal blend of comfort and style   </p>

                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">LOWER LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">817
                        SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">2</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATH</span><span class="value-list">1</span></li>
                    </ul>
                    <div class="apartment_button"><a href="https://drive.google.com/file/d/1YKrUOKZ4M2wOJh6zn5TzvsUsAOOoGMXo/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!!</a></div>
                  </div>
                  <div className="col-md-5">
                    <img src={img2} alt="logo" />

                  </div>

                </div>

              </div>
              <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>A two-level corner unit sprawling across 1142 sq. ft. The main floor introduces an airy living area, seamlessly flowing into an open-concept kitchen and dining space, accompanied by a second bedroom and bathroom. Ascend to the second floor to find a luxurious master bedroom, complete with a 5-piece ensuite and a walk-in closet, alongside a practical linen closet, epitomizing both elegance and functionality

                    </p>

                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">MAIN
                        LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">SECOND LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">1,142 SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">2</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATHS</span><span class="value-list">2</span></li>
                    </ul>
                    <div class="apartment_button"><a href="https://drive.google.com/file/d/1nzB8QyibA2uOmk8VDUHDe6hHjgkh3UrU/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!!</a></div>
                  </div>
                  <div className="col-md-5">
                    <img src={img3} alt="logo" />

                  </div>

                </div>

              </div>
              <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>A two-level interior unit spanning 1075 sq. ft. The main floor is thoughtfully arranged with a lively living space, an open-concept kitchen, and dining area, enhanced by a convenient powder room. Upstairs, the master bedroom awaits with a 5-piece ensuite and walk-in closet, accompanied by a linen closet, perfectly balancing luxury with practicality


                    </p>
                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">MAIN
                        LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">SECOND LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">1,078 SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">2</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATHS</span><span class="value-list">2</span></li>
                    </ul>
                    <div class="apartment_button"><a href="https://drive.google.com/file/d/1oY39z1X560i7IFHKcfKQfBRoZB3pPuRl/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!!</a></div>
                  </div>
                  <div className="col-md-5">
                    <img src={img4} alt="logo" />

                  </div>

                </div>

              </div>
              <div className="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>A spacious 1369 sq. ft. corner unit spanning two storeys, with 3 beds, 2.5 baths. Features include a large living area, expansive kitchen, dining space, and powder room on the second level, and a master bedroom with a 5-piece ensuite, two additional bedrooms, a 4-piece bathroom, laundry, and terrace on the third level.

                    </p>
                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">SECOND LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">THIRD LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">1,369 SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">3</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATHS</span><span class="value-list">2.5</span></li>
                    </ul>
                    <div class="apartment_button"><a href="https://drive.google.com/file/d/1Dz5DRQVrC3heT_J2e5MTfvF-XGPWQkAn/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!!</a></div>
                  </div>
                  <div className="col-md-5">
                    <img src={img5} alt="logo" />

                  </div>

                </div>

              </div>
              <div className="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6-tab">
                <div className="row g-4 justify-content-between pt-5">
                  <div className="col-md-6">
                    <p>A streamlined 1297 sq. ft. layout in a similar two-storey corner unit design, offering 3 beds and 2.5 baths. Comprises a comfortable living area, kitchen, dining, and powder room on the second level, with a master bedroom with ensuite, two more bedrooms, another bathroom, laundry, and a terrace on the third level



                    </p>
                    <ul class="apartment-items">
                      <li class="apartment-list-item"><span class="title-list text-uppercase">SECOND LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">THIRD LEVEL</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">1,297 SQ FT</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BEDS</span><span class="value-list">3</span></li>
                      <li class="apartment-list-item"><span class="title-list text-uppercase">BATHS</span><span class="value-list">2.5</span></li>
                    </ul>
                    <div class="apartment_button"><a href="https://drive.google.com/file/d/1Ve2T6Ijwi5BLEimcVUtfj6bEuzRH_YuQ/view?usp=sharing" target="_blank" class="btn btn-outline-primary w-100">Download Floor Plan!!</a></div>
                  </div>
                  <div className="col-md-5">
                    <img src={img6} alt="logo" />

                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="about-contact sec-padd" id="contact"
        ref={contactRef}>
          <div className="container">
            <div className="row g-md-5 g-4  justify-content-between">
              <div className="col-md-6">
                <h2>About the Builder</h2>
                <p>Crescent Homes journey started more than a decade ago. Superior design, quality craftsmanship, treating every home being built as our own family homes are the hallmarks of Crescent Homes. Crescent Homes communities are more than a home, it’s a lifestyle.

                </p>


                <div className="row g-4 pt-5 mt-4">
                  <div className="col-md-6">
                    <div className="flip_main">
                      <img src={man1} alt="Amit Airi" />
                      <div className="box-front">
                        <h3>  AMIT AIRI</h3>
                        <p>  BROKER</p>
                      </div>
                      <div class="box-hover">
                        <h3> AMIT AIRI</h3>
                        <p> BROKER</p>
                        <p> Phone: +1 (519) 623-0000 <br />
                          Email: info@amitairi.com</p>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="flip_main">
                      <img src={man2} alt="Amit Airi" />
                      <div className="box-front">
                        <h3> ASHISH WADHWA</h3>
                        <p> SALES REPRESENTATIVE</p>
                      </div>
                      <div class="box-hover">
                        <h3> ASHISH WADHWA</h3>
                        <p> SALES REPRESENTATIVE</p>
                        <p> Phone: +1 (519) 221-3601<br />
                          Email: ashish.mw@gmail.com</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="col-md-5">

{/*
                <form>
                <h2>Request a Call Back</h2>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <input type="text" className="form-control" placeholder="First Name" />
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control" placeholder="Last Name" />
                    </div>
                    <div className="col-md-12">
                      <input type="email" className="form-control" placeholder="Email" />
                    </div>
                    <div className="col-md-12">
                      <input type="tel" className="form-control" placeholder="Phone" />
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control" placeholder="Message" />
                    </div>
                    <div className="col-md-12">
                      <p>  Are you currently working with a Realtor? <span className="text-danger">*</span>
                      </p>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="radio" id="radio1" />
                        <label class="form-check-label" for="radio1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="radio" id="radio2" checked />
                        <label class="form-check-label" for="radio2">
                          No
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <p> Are you a Realtor? <span className="text-danger">*</span>
                      </p>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="radio" id="radio01" />
                        <label class="form-check-label" for="radio01">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="radio" id="radio02" checked />
                        <label class="form-check-label" for="radio02">
                          No
                        </label>
                      </div>
                    </div>
                  </div>


                  <button type="submit" className="btn btn-danger">Submit Now</button>
                </form> */}
              <ContactUsWoods contactRef={contactRef} id="Contact"/>
              </div>

            </div>
          </div>
        </section>

        <footer className="copy-right">
          <div className="container text-center">
          <p>© 2024 OtterbeinWoods. All Rights Reserved</p>
          </div>
        </footer>



      </main>

    </>
  );
};

export default OtterbeinWoodsWebsite;
