import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
//import MovingCheckList from   '../../../public/pdf/Moving-Check-List.pdf' //  "../../images/pdf/Moving-Check-List.pdf"
import FlooringCareGuide from "../../images/pdf/Flooring-Care-Guide.pdf"
import CondensationInfoPackage from "../../images/pdf/Condensation-Info-Package.pdf"
import CabinetryCareGuide from "../../images/pdf/Cabinetry-Care-Guide.pdf"
import Tarion30DayForm1 from "../../images/pdf/Tarion_30Day_Form-1.pdf"
import TarionYearEndForm1 from "../../images/pdf/Tarion_YearEnd_Form-1.pdf"

function UsefulForm() {
  return (
    <>
      <section className="banner_sec useful_banner">
        <div className="container">
          <div className="inner_banner_text">
            <h1>Useful Forms</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/">
                  <span>Home </span>
                </a>
              </li>
              <li>&gt;</li>
              <li>Useful Forms</li>
            </ol>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </section>

      <section className="sec-padd usefull_Sec">
        <div className="container">
          <h2>Useful Forms</h2>

          <div className="row g-md-5 g-4">
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  // href={MovingCheckList}
                  // target="_blank"

                  href=  "https://drive.google.com/file/d/1CtxBumSYeQSH0FOWaPA6mgA0Z_NbUffR/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf />
                  Moving Checklist
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  href={"https://drive.google.com/file/d/1-exHRczEJDUZT1M2z-Xz-4-KM3vlrUEf/view"}
                  target="_blank"
                >
                  <FaRegFilePdf />
                 Flooring Care Guide
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  href={"https://drive.google.com/file/d/1dlcPtrJm8G5rPQP--CvAa3ZT2K_DUz-d/view"}
                  target="_blank"
                >
                  <FaRegFilePdf />
                 Condensation Guide
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  href={"https://drive.google.com/file/d/1cQ-8Lmgj-qj7oYkIgDmmkOzS-HlHbquG/view"}
                  target="_blank"
                >
                  <FaRegFilePdf />
                  Cabinetry Care Guide
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  href={"https://drive.google.com/file/d/159CHCgcc8Fme8MTE-_JHxCYGNKWNP6Je/view"}
                  target="_blank"
                >
                  <FaRegFilePdf />
                 Tarion - 30 day form
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="pdf_link">
                <a
                  href={"https://drive.google.com/file/d/1sLlQqjudqJhBI3rB-iPVcpVHnibc-24-/view"}
                  target="_blank"
                >
                  <FaRegFilePdf />
                  Tarion - 1 Year form
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UsefulForm;
