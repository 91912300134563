import React, { useState } from "react";
import expand_img from "../../images/icons/expand.svg";
import Georgie from "../../images/Georgie.png";
import RockHavenEstates from "../../images/RockHavenEstates.png";
import SouthCreek from "../../images/South-Creek.png";
import letsEscape from "../../images/lets-escape.png";
import Arkellhills from "../../images/arkellhills.jpg";
import TownHomes from "../../images/cover-town-homes.jpg";
import DoonsCrossing from "../../images/DoonsCrossing.png";
import StackedTown from "../../images/Stacked-Towns.jpg";
import Maple from "../../images/maple.png";
import Stratford from "../../images/stratford.jpg";
import fivestarnewhomes from "../../images/five-star-new-homes.png";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import doonTrails from "../../images/new-website/DOON-TRAILS.svg";

const Community = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const ComunityData = [
    {
      id: 1,
      name: "Rock Haven Estates",
      title1: "Now Selling",
      title2: "ROCKWOOD",
      image: RockHavenEstates,
      description: "A unique setting in the heart of Doon.",
      link: "https://rockhavenestates.ca/",
      future: false,
    },
    {
      id: 2,
      name: "The Georgie",
      title1: "Now Selling",
      title2: "CAMBRIDGE",
      image: Georgie,
      description:
        "The Georgie is located on the southwest side of Cambridge with close access to highway 24 and the 401.",
      link: "https://www.thegeorgie.ca/",
      future: false,
    },
    {
      id: 3,
      name: "Otterbein Woods",
      title1: "Now Selling",
      title2: "KITCHENER",
      image: letsEscape,
      description:
        "A unique setting on the banks of the Grand River. A new community of freehold & condominium urban towns.",
      link: '',
      //"/communities/otterbein-woods",
      //"http://www.otterbeinwoods.ca/",
      future: false,
    },
    {
      id: 4,
      name: "South Creek Towns",
      title1: "Now Selling",
      title2: "KITCHENER",
      image: SouthCreek,
      description: "A unique setting in the heart of Doon.",
      link: 'communities/south-creek', //work done
      //"https://www.southcreektowns.com/",
      future: false,
    },
    // {
    //   id: 5,
    //   name: "Arkell Hills",
    //   title1: "FUTURE RELEASE",
    //   title2: "GUELPH",
    //   image: Arkellhills,
    //   description: "Arkell Hills is our upcoming project.",
    //   link: "",
    //   future: true,
    // },
    {
      id: 6,
      name: "Stacked Urban Townhomes",
      title1: "SOLD OUT",
      title2: "KITCHENER",
      image: StackedTown,
      description:
        "Our Urban Townhomes make great starter homes for young couples and singles who enjoy their independence while staying close to home.",
      link: "",
      future: false,
    },
    {
      id: 7,
      name: "Single Family",
      title1: "SOLD OUT",
      title2: "KITCHENER",
      image: TownHomes,
      description:
        "Discover an enclave of townhomes set amidst one of the Kitchener most prestigious neighbourhoods, and one of Ontarios best places to live.",
      link: "",
      future: false,
    },
    {
      id: 8,
      name: "Doon Trails",
      title1: "SOLD OUT",
      title2: "KITCHENER",
      image: DoonsCrossing,
      description:
        "A new classic in urban luxury living perfectly tailored to the young & pleasant neighborhood.",
      link: "communities/doon-trails",
      future: false,
    },
    {
      id: 9,
      name: "Maple Keys",
      title1: "SOLD OUT",
      title2: "KITCHENER",
      image: Maple,
      description:
        "Located near the corner of Bleams and Homer Watson Blvd, this location boasts shopping convenience, public transit and 401 access.",
      link: "",
      future: false,
    },
    {
      id: 10,
      name: "Stratford Wood",
      title1: "SOLD OUT",
      title2: "STARTFORD",
      image: Stratford,
      description: "Stratford Wood is our upcoming project.",
      link: "",
      future: false,
    },
  ];

  const showDetails = (item) => {
    //  navigate(createSlug(name));
    console.log("name", item);

    if (item.link) {
      window.open(item.link, "_blank"); // Open in a new tab
    }
    // navigate(createSlug(item.name), {
    //   state: { item: item },
    // });
  };
  function createSlug(text) {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");
  }

  return (
    <>
      <section className="banner_sec community_banner">
        <div className="container">
          <div className="inner_banner_text">
            <h1>Communities</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/">
                  <span>Home </span>
                </a>
              </li>
              <li>&gt;</li>
              <li>Communities</li>
            </ol>
          </div>
        </div>
        <div
          className="elementor-shape elementor-shape-bottom"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>{" "}
        </div>
      </section>

      <section className="sec-padd bg_light pt-2 content_sec">
        <div className="container-fluid mb-5">
          <div className="blue_commmunity">
            <h3>CRESCENT HOMES</h3>
            <h3>BUILDING FINEST NEW HOME COMMUNITIES</h3>
            <img src={fivestarnewhomes} />
            <p>
              Crescent Homes have great building experience to create a
              prestigious selection of beautiful, high-quality, Energy
              efficient, reliable homes that surpass the expectations of their
              customers. Crescent Homes takes pride in offering the highest
              level of customer satisfaction.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row g-md-5 g-4">
            {ComunityData.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="card_c">
                    <div className="card_img">
                      <div className="labels-right">
                        <a href="#" className="">
                          {item.title1}
                        </a>
                        <a href="#" className="">
                          {item.title2}
                        </a>
                      </div>
                      <a
                        // href="/community/detail"

                        className="img_effect"
                        onClick={() => {
                          showDetails(item);
                        }}
                      >
                        <img src={item.image} />
                      </a>
                      <div
                        className="item-tools"
                        onClick={() => {
                          setOpenModal(true);
                          setSelected(item);
                        }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#Bigmodel"
                        >
                          <img src={expand_img} />
                        </a>
                      </div>
                    </div>
                    <div
                      className="card_c_content"
                      onClick={() => {
                        showDetails(item);
                      }}
                    >
                      <a
                      //href="/community/detail"
                      >
                        {item.name}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {selected && (
        <div
          className="modal fade modal_big"
          id="Bigmodel"
          tabindex="-1"
          aria-labelledby="BigmodelLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-5">
                <div className="row g-4 justify-content-between">
                  <div className="col-md-8">
                    <img src={selected.image} />
                  </div>
                  <div className="col-md-4">
                    <div className="right_modal">
                      <div className="labels-right">
                        <a href="#" className="">
                          {selected?.title1}
                        </a>
                        <a href="#" className="">
                          {selected?.title2}
                        </a>
                      </div>
                      <h2 className="item-title">
                        <a>{selected?.name}</a>
                      </h2>
                      <p className="item-title">{selected?.description}</p>
                      <div
                        className="mt-4"
                        onClick={() => {
                          showDetails(selected);
                        }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#Bigmodel11"
                          className="c_btn light_green"
                        >
                          Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Community;
