export const endPoints = {
  //apiBaseUrl: 'https://accountapi-dev.bettdraft.com:5000/',  //Dev Url
  apiBaseUrl: "https://accountapi.bettdraft.com:5000/", //
  //apiBaseUrl: 'https://accountapiprod.bettdraft.com:5000/',  //prod

  api: {
    EMAIL_SUPPORT:"crescenthomes/support",
    EMAIL_SUPPORT_DOON:"crescenthomes/contact",
   
  },
  mode: "Development",
};

export const appVersion = {
  version: "2.1.3",
};

export default {};
